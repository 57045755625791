import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Router from 'next/router';

import * as loginActions from '../redux/reducers/auth';
import handleErrors from '../utils/error-handler';

import LoginComponent from '../components/Login';

function Login({ login }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      login({ username, password })
        .then(() => {
          Router.push({ pathname: '/deliveries' });
        })
        .catch(handleErrors);
    },
    [username, password]
  );

  const handleChange = useCallback((e) => {
    const name = e.target.name;
    const value = e.target.value;
    switch (name) {
      case 'username':
        setUsername(value);
        break;
      case 'password':
        setPassword(value);
        break;
      default:
        break;
    }
  }, []);

  return <LoginComponent username={username} password={password} onChange={handleChange} onSubmit={handleSubmit} />;
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ login: loginActions.login }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Login);
