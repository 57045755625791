import { Grid, Row, Col, FormGroup, ControlLabel, FormControl, Button } from 'react-bootstrap';

function FieldGroup({ id, label, help, ...props }) {
  return (
    <FormGroup controlId={id}>
      <ControlLabel>{label}</ControlLabel>
      <FormControl {...props} />
      {help && <HelpBlock>{help}</HelpBlock>}
    </FormGroup>
  );
}

export default ({ username, password, onChange, onSubmit } = {}) => {
  return [
    <Grid>
      <Row
        style={{
          height: '100vh',
          paddingTop: 'calc((100vh - 420px) / 2)',
          paddingBottom: 'calc((100vh - 420px) / 2)',
        }}
      >
        <Col sm={8}>
          <a href="https://www.ezadmin.co.kr/sub_01_76.html" target="_blank" style={{ width: '100%', height: '100%' }}>
            <img style={{ width: '100%' }} src="/static/login/login-left-recommend.jpg" alt="이지어드민" />
          </a>
        </Col>
        <Col sm={4}>
          <Row>
            <Col sm={10} smOffset={1}>
              <Row>
                <Col sm={8} smOffset={2}>
                  <img style={{ width: '100%' }} src="/static/common/doobal-logo.png" alt="두발히어로 로고" />
                </Col>
              </Row>

              <h3>기업고객 전용 퀵서비스</h3>
              <h4>기업회원 접수/조회용</h4>

              <form onSubmit={onSubmit}>
                <FieldGroup
                  name="username"
                  type="text"
                  placeholder="사용자 ID를 입력하십시오."
                  value={username}
                  onChange={onChange}
                />

                <FieldGroup
                  name="password"
                  type="password"
                  placeholder="암호를 입력하십시오."
                  value={password}
                  onChange={onChange}
                />

                <Button type="submit" bsStyle="primary" block>
                  로그인
                </Button>
              </form>
            </Col>
          </Row>
        </Col>
      </Row>
    </Grid>,
    <footer
      style={{
        position: 'fixed',
        bottom: '0px',
        height: '39px',
        width: '100%',
        textAlign: 'center',
        backgroundColor: '#585756',
      }}
    >
      <Grid>
        <p
          style={{
            color: 'white',
            fontSize: '14px',
            lineHeight: 1.29,
            letterSpacing: '-0.6px',
            padding: '9px 0px',
          }}
        >
          ㈜체인로지스 서울특별시 용산구 독서당로 39, 1층 두발히어로 물류센터(신성미소시티) 1566-0580
        </p>
      </Grid>
    </footer>,
  ];
};
